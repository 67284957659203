import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserHistory } from 'history';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import GlobalStyle from '@ubeya/shared-web/style/global';
import themeMaker from '@ubeya/shared-web/style/themeMaker';
import App from 'app';

const history = createBrowserHistory();

const REACT_QUERY_CONFIG = { defaultOptions: { queries: { staleTime: Infinity, refetchOnWindowFocus: false } } };

const queryClient = new QueryClient(REACT_QUERY_CONFIG);

const CustomGlobalStyle = createGlobalStyle`
  body {
    color: ${({ theme }) => theme.colors.gray000};
    background:${({ theme }) => theme.colors.surface} !important;
  }
`;

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <ThemeProvider theme={themeMaker()}>
    <Router history={history}>
      <GlobalStyle />

      <CustomGlobalStyle />
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </RecoilRoot>
    </Router>
  </ThemeProvider>
);
